import React, { Component } from 'react';
import Survey from '../../../templates/Survey/Survey';

import './IndexLayout1.css'

class IndexLayout1 extends Component {

    state = {  }

    render() { 
        return ( 
            <div className="container-fluid mb-5">
                <div className="h-100">
                    <div className="h-100">
                        <div className="container h-100">
                            <div className="p-4">
                                <div className="mb-maxw-500 m-auto">
                                    <h2 className={this.props.getHeaderClasses()} style={this.props.getHeaderStyles()}>{this.props.content.headerText}</h2>
                                    {this.props.content.descriptionText.length > 0 &&
                                        <p className={this.props.getDescriptionClasses()} style={this.props.getDescriptionStyles()}>{this.props.content.descriptionText}</p>
                                    }
                                </div>
                            </div>
                            <div className="mb-survey-1 layout1">
                                <Survey surveyId={this.props.surveyId} mbClass="m-auto rounded" layout={this.props.layout} propertyId={this.props.propertyId} siteId={this.props.siteId} survey={this.props.survey} avtc={this.props.avtc} mbi={this.props.mbi} pushnamiOverride={this.props.pushnamiOverride} loadIframe={this.props.loadIframe} changePage={this.props.changePage}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
 
export default IndexLayout1;