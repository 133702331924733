import React, { Component } from 'react';
import './AdListItem.css'

class AdListItem extends Component {
    state = {  }

    render() { 
        return ( 
            <div className="adlistitem card">
                <div className="adlistitem-flex card-body p-0 m-0">
                    <a
                        className="adlistitem-logo rounded-left border-right"
                        alt={this.props.title}
                        href={this.props.url}
                        style={{backgroundImage: `URL('${this.props.image}')`}}>    
                    </a>
                    <div className="p-3 text-left">
                        <div className="font-weight-bold">{this.props.title}</div>
                        <div>
                            {Array.isArray(this.props.description) && typeof this.props.description === 'object' ?
                                <ul>
                                    {this.props.description.map( bulletPoint => {
                                        return <li key={bulletPoint} className="mt-2">{bulletPoint}</li>
                                    })}
                                </ul>
                            :
                                this.props.description
                            }                            
                        </div>
                        <div className="mt-2">
                            <a
                                className="btn btn-primary"
                                target="_blank"
                                rel="noopener noreferrer"
                                alt={this.props.title}
                                href={this.props.url}>
                                    {this.props.button ? this.props.button : 'Learn More'}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
 
export default AdListItem;